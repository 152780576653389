import {EnvironmentType} from "src/app/enums/enums";

export const environment = {
  production: false,
  //apiUrl: 'http://localhost:50216/',
  //apiUrl: 'https://emaapidev.clavisco.com/',
  apiUrl: 'https://emadcrapidev.clavisco.com/',
  name: `DemoCR`,
  type: EnvironmentType.Development,
  env: 'Development'
};



