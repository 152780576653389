import {ICompanyAdjustmentInventory} from "../interfaces/i-company";

export enum EnvironmentType {
  Development = 'Development',
  Testing = 'Testing',
  Production = 'Production'
}

export enum UserPageTabIndexes {
  User,
  UserAssign
}


export enum PrintFormat {
  SaleOffer,
  SaleOrder,
  VoucherCancellation,
  Invoices,
  PinPadInvoices,
  CreditNote,
  ReceivedPayment,
  CashClosing,
  ReprintSaleOffers,
  ReprintInvoices,
  ReprintSaleOrders,

  CashInvoice,
  CreditInvoice,
  Balance,
  ReprintCashInvoice,
  ReprintCreditInvoice,
  ReprintReceivedPayment
}

export enum SettingCode {
  PrintFormat = 'PrintFormat',
  Account = 'Account',
  ValidateInventory = 'ValidateInventory',
  ReportManager = 'ReportManager',
  Route = 'Route',
  DefaultBusinessPartner = 'DefaultBusinessPartner',
  Decimal = 'Decimal',
  Payment = 'Payment',
  LineMode = 'LineMode',
  AdjustmentInventory = 'AdjustmentInventory',
  MemoryInvoice = 'MemoryInvoice',
  Margin = 'Margin',
  PatternTypeahead = 'PatternTypeahead',
  Points = 'Points',
  FieldsConfiguredSAP = 'FieldsConfiguredSAP',
  MobileAppConfiguration = 'MobileAppConfiguration',
  SchedulingConfiguration = 'SchedulingConfiguration',
  EventViewer = 'EventViewer'
}

export enum DocumentType {
  Quotations = 'OQUT',
  Orders = 'ORDR',
  Invoices = 'OINV',
  Purchase = 'OPDN',
  PurchaseOrder = 'OPOR',
  PurchaseReturns = 'ORPD',
  IncomingPayments = 'ORCT',
}

export enum PreloadedDocumentActions {
  EDIT = 'edit',
  COPY = 'copy',
  DUPLICATE = 'duplicate'
}

export enum CopyFrom {
  OPOR = 22,
  OINV = 13,
  OQUT = 23,
  ORDR = 17
}


/**
 * Identificar si item se maneja con series y lotes
 */
export enum ItemSerialBatch {
  NA = 1,
  Serie,
  Lote,

}

export enum ItemsClass {
  Service = '1',
  Material = '2'
}


export enum ViewBatches {
  FACTURACION = 1,
  TRANSFER_INVENTORY = 2
}

export enum RouteStatus {
  INACTIVE,
  ACTIVATED,
  FINISHED,
  CLOSED
}

export enum RouteType {
  ORDER,
  DELIVERY
}

export enum RouteCheckTypes {
  DESTINATION,
  FAILED_EXIT,
  SUCCESS_EXIT,
  AUTOMATIC_POINT,
  ARRIVAL,
  NOT_LINKED_TO_ROUTE,
  ROUTE_START,
  END_OF_ROUTE
}

export enum DocumentSyncStatus {
  All = '',
  InQueue = 'Q',
  Processing = 'P',
  Errors = 'E',
  Success = 'S'
}

export enum Event {
  All = "",
  Information = "I",
  Success = "S",
  Warning = "W",
  Error = "E"
}

export enum DocumentSyncTypes {
  All = '',
  SaleOrder = 'OR',
  SaleQuotation = 'OF',
  Invoice = 'IV',
  InvoiceWithPayment = 'IP',
  IncomingPayment = 'PA'
}

export enum ModalSalesInfo {
  ACEPT = 1,
  REIMPRIMIR = 2
}

export enum Titles {
  Factura = 'Factura',
  Orden = 'Orden',
  Cotizacion = 'Cotización',
  NC = 'Nota'
}

export enum BaseTypeLines {
  SALE_ORDER = 23,
  INVOICE = 17

}
