import {Injectable} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {AlertsService} from '@clavisco/alerts';
import {catchError, forkJoin, map, Observable, of, switchMap, tap} from 'rxjs';
import {IPrincipalComponentResolvedData} from '../interfaces/i-resolvers';
import {MenuService} from '../services/menu.service';
import {SettingsService} from "../services/settings.service";
import {Repository} from "@clavisco/core";
import {ICurrentCompany, ICurrentUser} from "../interfaces/i-localStorage";
import {AssignsService} from "../services/assigns.service";
import {IUserAssign} from "../interfaces/i-user";
import {ExchangeRateService} from "../services/exchange-rate.service";
import {WarehousesService} from "../services/warehouses.service";
import {IExchangeRate} from "../interfaces/i-exchange-rate";
import {IWarehouse} from "../interfaces/i-warehouse";

@Injectable({
  providedIn: 'root'
})
export class PrincipalComponentResolver implements Resolve<IPrincipalComponentResolvedData | null> {
  constructor(
    private menuService: MenuService,
    private alertsService: AlertsService,
    private settingService: SettingsService
    ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPrincipalComponentResolvedData | null> {

    const menuService$ = this.menuService.Get()
      .pipe(
        map(x => {
          this.alertsService.ShowAlert({Response: x});
          return x;
        }),
        catchError(err => {
          this.alertsService.ShowAlert({HttpErrorResponse: err});
          return of(null)
        })
      );

    const settingService$ = this.settingService.Get()
      .pipe(
        map(x => {
          this.alertsService.ShowAlert({Response: x});
          return x;
        }),
        catchError(err => {
          this.alertsService.ShowAlert({HttpErrorResponse: err});
          return of(null)
        })
      );


    return forkJoin(
      [
        menuService$,
        settingService$
      ]
    ).pipe(
      map((res) => {
        return {
          MenuOptions: res[0]?.Data,
          Setting: res[1]?.Data
        } as IPrincipalComponentResolvedData;
      }),
      catchError(err => {
        this.alertsService.ShowAlert({HttpErrorResponse: err});
        return of(null)
      })
    );

  }
}
