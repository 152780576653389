import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {map, Observable, startWith} from "rxjs";
import {IBusinessPartner} from "../../interfaces/i-business-partner";
import {CLPrint} from "@clavisco/core";
import {CL_DISPLAY} from "@clavisco/linker";
import {FormControl, FormGroup} from "@angular/forms";
import {SharedService} from "../shared.service";

@Component({
  selector: 'app-autocomplete-business-partner',
  templateUrl: './autocomplete-business-partner.component.html',
  styleUrls: ['./autocomplete-business-partner.component.scss']
})
export class AutocompleteBusinessPartnerComponent implements OnInit {
  businessPartner$!:  Observable<IBusinessPartner[]>;
  @Input() businessPartner!: IBusinessPartner[];
  @Output() onAutocompleteClicked: EventEmitter<IBusinessPartner> = new EventEmitter<IBusinessPartner>();
  @Input() businessPartnerFormControl!: FormControl;
  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {

    if(!this.businessPartner || !this.businessPartner.length)
    {
      CLPrint('No action buttons was provide', CL_DISPLAY.WARNING);
      this.businessPartner = [];
    }
    this.businessPartner$ = this.businessPartnerFormControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        if (value) {
        return this.FilterBusinessPartner(value);
      }
        return this.businessPartner;
      }),
    );

  }

  ngAfterViewInit(): void {
    this.sharedService.onAutcompleteBp$.next(false);
  }

  displayFn(_businessP: IBusinessPartner): string {
    return _businessP && Object.keys(_businessP).length ? `${_businessP.CardCode} - ${_businessP.CardName}` : '';
  }

  public onSelectOption(_businessP: IBusinessPartner): void {
    this.onAutocompleteClicked.emit(_businessP);
  }

  FilterBusinessPartner(_value: string | IBusinessPartner): IBusinessPartner[]
  {
    if(typeof _value !== 'string')
    {
      return this.businessPartner.filter(bp => bp.CardCode === _value.CardCode);
    }
    return this.businessPartner.filter(bp => (`${bp.CardCode}${bp.CardName}`).toLowerCase().includes(_value.toLowerCase()))
  }
}
