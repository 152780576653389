import {IPorts} from "@app/interfaces/i-company";

export enum StorageKey {
  Session = 'Session',
  CurrentCompany = 'CurrentCompany',
  CurrentUserAssign = 'UserAssign',
  DocumentInMemories = 'DocumentInMemories',
  CurrentSession = 'CurrentSession',
  Shorcuts = 'Shorcuts',
  Ports = 'Ports'
}
